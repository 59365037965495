
import Vue from "vue"
import { UnionItemDefinition } from "@/types/responses"
export default Vue.extend({
	props: {
		parentModulePath: String,
	},
	computed: {
		tabs(): undefined | UnionItemDefinition["subItems"] {
			const mod = this.$vStore.state.nav.modules?.items.find((mod) => {
				return mod.route.split("/")[1] === this.parentModulePath
			})
			if (mod && mod.subItems.length > 0) {
				const permitted = mod.subItems.filter(sub => {
					return sub.isPermitted
				})
				console.log(mod)
				return permitted
			} else {
				return undefined
			}
		},
		defaultTab(): undefined | string {
			const mod = this.$vStore.state.nav.modules?.items.find((mod) => {
				return mod.route.split("/")[1] === this.parentModulePath
			})
			if (mod && mod.subItems.length > 0) {
				const firstPermitted = mod.subItems.find(mod => {
					return mod.isPermitted
				})
				return firstPermitted ? firstPermitted.route : undefined
			} else {
				return undefined
			}
		}
	},
})
